<template>
  <div class="v-slots-lunch-mobile">
    <div
      class="v-slots-lunch-mobile-title"
      v-html="slotProduct.Name"
    />
    <div class="v-scrollbar">
      <template v-if="appConfig.VueSettingsPreRun.MenuSlotsLayout === 'Erebor'">
        <common-cards-flex-mesh
          :items="slotProducts"
          :max-items="3"
          :mesh-gap="16"
          :min-items="isNotUltraSmall ? 3 : 1"
        >
          <template #item="item: ProductInList">
            <div
              class="v-slot-product-single"
              :class="{ active: selectedSlotId === item.ID }"
              data-test-id="slots-lunch-add-product"
              @click="() => selectSlotLocal(slotProduct?.ID, item)"
            >
              <arora-nuxt-image
                :key="item.ID"
                :image="
                  imageExists(item.CombineMobileImage) ? item.CombineMobileImage : item.NormalImage
                "
                :alt="item.Name"
                :image-type="imageExists(item.CombineMobileImage) ? 'CombineMobile' : 'ProductNormal'"
              />
              <div
                class="v-slot-product-single-title-popup"
                v-html="sanitize(item.Name)"
              />
              <div
                v-if="slotProduct.Products.some((prod) => prod.Price > 0)"
                class="v-slot-product-single-subtitle-popup v-d-flex v-align-items-center v-justify-content-center"
              >
                <common-currency :amount="item.Price" />
              </div>
            </div>
          </template>
        </common-cards-flex-mesh>
      </template>
      <template v-else-if="appConfig.VueSettingsPreRun.MenuSlotsLayout === 'Gladden'">
        <common-cards-flex-mesh
          :items="slotProducts"
          :max-items="1"
          :mesh-gap="16"
        >
          <template #item="item: ProductInList">
            <div
              class="v-slot-product-single v-slot-product-single-gladden"
              :class="{ active: selectedSlotId === item.ID }"
              data-test-id="slots-lunch-add-product"
              @click="() => selectSlotLocal(slotProduct?.ID, item)"
            >
              <arora-nuxt-image
                :key="item.ID"
                :image="
                  imageExists(item.CombineMobileImage) ? item.CombineMobileImage : item.NormalImage
                "
                :alt="item.Name"
                :image-type="imageExists(item.CombineMobileImage) ? 'CombineMobile' : 'ProductNormal'"
              />
              <div class="v-slot-product-single-gladden-more">
                <div
                  class="v-slot-product-single-title-popup"
                  v-html="sanitize(item.Name)"
                />
                <div
                  class="v-slot-product-single-subtitle-popup v-d-flex v-align-items-center v-justify-content-center"
                  v-html="sanitize(item.Description)"
                />
              </div>
            </div>
          </template>
        </common-cards-flex-mesh>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ProductInList, Slot } from '~types/menuStore'

import { type GUID, useWindowSize } from '@arora/common'

const { selectSlot, slotProduct } = defineProps<{
  slotProduct: Slot
  selectedSlotId: GUID | undefined
  selectSlot: (slotId: GUID | undefined, item: ProductInList) => void
}>()

const menuStore = useMenuStore()
const popupStore = usePopupStore()

const { sanitize } = useI18nSanitized()
const { imageExists } = useImageInfo()
const appConfig = useAppConfig()
const { isNotUltraSmall } = useWindowSize()

const slotProducts = computed<ProductInList[]>(() =>
  (appConfig.VueSettingsPreRun.MenuPageHideStopList
    ? (slotProduct?.Products ?? []).filter((product) => !menuStore.StopListProductIds.has(product.ID))
    : (slotProduct?.Products ?? [])
  ).filter(
    (product) =>
      !menuStore.BlockedProductIds.has(product.ID) && !menuStore.NonActiveProductIds.has(product.ID)
  )
)

function selectSlotLocal(slotId: GUID | undefined, item: ProductInList): void {
  selectSlot(slotId, item)

  popupStore.closePopup()
}
</script>

<style lang="scss">
@use '~/assets/variables';

.v-slots-lunch-mobile {
  .v-slots-lunch-mobile-title {
    font-size: variables.$TextSizeH3;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .v-slot-product-single {
    &.active {
      border: 1px solid variables.$SecondaryBackgroundColor;
      border-radius: variables.$BorderRadius;
    }
  }

  .v-slot-product-single-gladden {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 10px;

    .v-img-fluid {
      flex: 0 0 30%;
      max-width: 30%;
      height: auto;
    }

    .v-slot-product-single-gladden-more {
      flex: 0 0 calc(70% - 10px);
      max-width: calc(70% - 10px);

      .v-slot-product-single-title-popup {
        text-align: left;
      }

      .v-slot-product-single-subtitle-popup {
        text-align: left;
      }
    }
  }
}

.v-slot-product-single-title-popup {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}
.v-slot-product-single-subtitle-popup {
  text-align: center;
  margin: 10px 0;
}
</style>
